import React, { useState, useEffect } from "react"
import { Seo } from "../seo"

import baccaratCard from "../../images/bacc_card.jpg"
import bjForum from "../../images/forum_magazine.jpeg"
import dailyTimes from "../../images/mdt_image_full.jpg"
import {
  article,
  centerContainer,
  keyCard,
  imageContainer,
  preShuffle,
  bonus,
} from "./styles.module.less"

export const WhatYouShouldKnowArticle = () => {
  if (typeof window === "undefined") {
    global.window = {}
  }

  const [windowWidth, setWindowWidth] = useState(600)

  let videoWidth
  let videoHeight

  useEffect(() => {
    if (window.innerWidth && window.innerWidth > 900) {
      setWindowWidth(900)
    } else if (window.innerWidth && window.innerWidth < 500) {
      setWindowWidth(window.innerWidth)
    }
  }, [])

  if (windowWidth > 675) {
    videoWidth = windowWidth * 0.95
  } else {
    videoWidth = windowWidth
  }

  videoHeight = videoWidth * 0.5625

  return (
    <article className={article}>
      <h1>
        What you should know about Game Protection, Memory, and Sleight of Hand
      </h1>

      <aside>
        This article contains external links. These links are being provided as
        a convenience and for informational purposes only; they do not
        constitute an endorsement or an approval by Sal Piacente and UniverSal
        Game Protection Development, Inc. of any of the products, services or
        opinions of the corporation or organization or individual. UniverSal
        Game Protection Development, Inc. and Sal Piacente bear no
        responsibility for the accuracy, legality or content of the external
        site or for that of subsequent links. Contact the external site for
        answers to questions regarding its content.
      </aside>

      <p>
        Someone recently asked me, “What does memory have to do with Game
        Protection?” This question threw me for a loop at first, because the
        people I do business with (my clients and trainees), closest friends,
        respected colleagues and acquaintances “in the loop,” can all easily
        answer this question, but it never really occurred to me that other
        people in the casino industry (specifically Table Games and
        Surveillance) just don’t know. The question of how much damage a trained
        memory can cause to a casino’s bottom line is a great question, which I
        plan to answer here. Some of the biggest scams today, as well as some of
        the most effective advantage plays (legal methods used by players to
        gain an advantage over the house, as opposed to cheating), require
        memory skills that can be easily acquired with a bit of training, though
        practice is needed to be proficient enough to use those skills
        effectively. A trained memory is a very powerful weapon to those who
        like to see a casino as a big ATM for their convenience.
      </p>
      <p>
        During some of my training sessions, I’ll ask a trainee to shuffle a
        deck of cards. After shuffling the cards, the volunteer hands me the
        deck and I memorize the shuffled cards in a matter of seconds. This is
        not to show off (though it is quite gratifying), but to show that this
        is possible and the skill is easily taught to someone with the
        motivation to learn. In this case, the motivation is money. A 2008 news
        article from the Macau Daily Times (shown later in this article)
        features a dealer who was caught with more than half a million dollars
        (HKD) in his bank account, presumably 20% of the $3 million score. And
        what was the most critical skill in this convict’s cheating arsenal?
        Memory, of course!
      </p>

      <h2>Advantage Plays and Memory</h2>

      <p>
        First, let’s examine the relationship between memory and certain
        advantage plays. For card counters (a type of advantage player or AP), a
        great memory is not necessary, as they are only keeping track of one or
        two numbers at most: the running count and the true count. A player
        looking to legitimately capitalize on a blackjack game using an advanced
        strategy would essentially need a stronger memory. For example, let’s
        take a look at Slug Tracking and Sequential Tracking or “Ace Tracking,”
        as it is commonly known, both being forms of Shuffle Tracking.
      </p>

      <div className={centerContainer}>
        <blockquote>
          “Shuffle Tracking is an advanced card counting strategy. It does not
          replace card counting; it enhances it.” -
          <strong> The Blackjack Shuffle Tracker’s Cookbook </strong>
          by Arnold Snyder
        </blockquote>

        <blockquote>
          “No other advantage strategy has forced the industry to reevaluate
          traditional procedures and implement so many changes and
          countermeasures.” -
          <strong> Casino Game Protection: A Comprehensive Guide </strong> by
          Steve Forte (on Shuffle Tracking)
        </blockquote>
      </div>

      <aside>
        It is important to note that any hand shuffle is exploitable and that
        the casino makes no money during the shuffle procedure. Therefore, for
        the sake of productivity and profitability, it is impossible for any
        hand shuffle procedure to truly randomize the cards within an acceptable
        period of time. So casinos should use a good mix of shuffle methods in a
        proper sequence, but ensure that the shuffle is not too long. I
        recommend 90 seconds or less for multi-deck hand shuffle procedures.
      </aside>

      <p>
        Essentially, Shuffle Trackers are able to identify a clump of high-value
        cards during play, then follow that clump to the discard rack, and
        through the shuffle, so that they can exploit the information during the
        next shoe. Knowing when that clump of high cards will be dealt, Shuffle
        Trackers can increase their bets accordingly and gain a significant
        advantage over the house. This may sound simple, yet it requires
        well-cultivated skills. With all casinos being closed at the time of
        this article due to the COVID-19 threat and the economy in the state
        that it is in, I’d say there is plenty of time and incentive for AP’s to
        learn and practice these skills, so it is important that Table Games and
        Surveillance are, at the very least, aware of how the different Shuffle
        Tracking methods work.
      </p>

      <h2>Advantage Plays: Slug Tracking</h2>

      <div className={centerContainer}>
        <blockquote>
          “…tracking (visually following) slugs of high cards through the
          shuffle in a shoe game, is one of the most powerful and most invisible
          strategies in use by professional players today. The truth is,
          however, that it is also one of the most difficult strategies to apply
          successfully.” -{" "}
          <strong>The Blackjack Shuffle Tracker’s Cookbook </strong> by Arnold
          Snyder{" "}
        </blockquote>
      </div>

      <p>
        Slug Tracking is identifying a post shuffle slug that is rich in high
        (aces and 10-value cards) or low cards. For more information, I
        recommend reading The Blackjack Shuffle Tracker’s Cookbook by Arnold
        Snyder, world renowned blackjack expert, highly skilled AP, and author.
        In order to shuffle track, players must be able to keep several
        different running counts in their heads at the same time, then refer
        back to those numbers at a later point, at which time they must add to
        or subtract from those numbers. Each shuffle is “mapped” so that these
        players know which segments of cards will be joined together through the
        shuffle procedure. If these AP’s know that the two packets that will be
        joined together after the shuffle have running counts of -7 and -8, then
        they know that the resulting section will be rich in high cards (15 of
        them) and will either cut this packet to the top of the shoe, or keep
        track of it at another location, to exploit that information when these
        cards are to be dealt from the shoe. To maximize their ability to
        capitalize on this strategy, players must be able to remember multiple
        numbers (representing multiple sections of the shoe). The memory skills
        required for the next AP strategy are even sharper, as they must be able
        to memorize multiple cards, including the suit and the value.
      </p>

      <h2>Advantage Plays: Ace Tracking</h2>

      <p>
        In Ace Tracking, another AP technique and more elaborate form of Shuffle
        Tracking, the dealer legitimately shuffles the cards and the AP can
        predict, with pretty good accuracy, when an ace will appear (based on
        information memorized from the previous shoe), giving him/her the
        opportunity to bet big at that point with a substantial advantage over
        the house. In blackjack, when a player knows that the first card of a
        hand dealt to them will be an ace, they have a 52% advantage over the
        house. This is monstrous!{" "}
      </p>

      <aside>
        Note: The premise works with shuffles where the sequence of cards are
        not disturbed, only separated a bit, i.e., no box nor strip in the
        shuffle procedure.
      </aside>

      <p>
        For example, in the picture below, the player would memorize the three
        cards (suit and value) in sequence, called the key cards, which will be
        on top of the targeted ace after the dealer picks the cards up and
        places them into the discard rack. The ace (with suit) is also
        memorized, so that the order for this sequence would be queen of hearts,
        4 of spades, 7 of clubs, and ace of spades, in that exact order.{" "}
      </p>

      <aside>
        Note: Proper procedure dictates that the dealer picks up the cards in
        this sequence.
      </aside>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <iframe
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube.com/embed/y7fkXOoC0e8"
          title="Sal Piacente Demonstrates Ace Tracking"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        After the shuffle and on the next shoe, the AP begins looking for the
        key cards in the order memorized. The key cards (underlined) are dealt
        in order, yet separated by the cards in-between them (see pic below):{" "}
        <span className={keyCard}>queen of hearts</span>, (followed by the
        dealer’s 10 and the first player’s deuce), then the{" "}
        <span className={keyCard}>four of spades</span> (followed by the three
        and dealer’s 4), and finally the{" "}
        <span className={keyCard}>seven of clubs</span> (followed by the
        player’s six hit card and the dealer’s six).
      </p>

      <p>
        We see that there are now two cards in between each of the key cards
        after the shuffle, which means that the dealer’s shuffle was consistent
        (which under other circumstances we would consider a good skill for a
        dealer) and great for tracking, and two cards after the last key card (7
        of clubs), so it is very highly likely that the target ace of spades
        will make its appearance within the first two cards of the next hand. To
        ensure that the ace is dealt to the AP and kept away from the dealer (in
        case the calculations are one card off), the AP would make three large
        bets on the next hand, with about a 17% player advantage, (dividing the
        52% advantage mentioned earlier by 3 hands).
      </p>

      <p>
        If a player wants to be able to “key” or identify four aces, then he/she
        must be able to memorize 16 specific cards (three key cards before each
        ace and the ace itself) with both suit and value. On top of that, the
        person doing the keying would have to be able to identify the sixteen
        memorized cards in order, (disregarding the other cards in between
        them), and depending on skill level, begin to memorize cards for the
        next shoe. In a well-organized team, a certain player is tasked with
        memorizing and tracking the cards, while others make the big bets to
        exploit the knowledge. This invaluable memory pro is referred to as the
        KG, key girl or key guy. Renowned card counter Tommy Highland organized
        one of the most skillful teams of advantage players that have ever
        legitimately exploited casinos. Below is an excerpt from the Highland
        manual, stressing how crucial it is to provide the KG with a nurturing
        environment.
      </p>

      <div className={centerContainer}>
        <blockquote>
          “Creating a Peaceful Atmosphere: The Keying skills used in this
          project are very difficult. When a KG’s concentration is impaired,
          there is a remarkable decline in her ability to key. For this reason,
          please refrain from bringing up disturbing topics immediately prior to
          or during session. Try to delay these discussions until after the
          session is over. During a session do not make disturbing comments
          regarding the KG’s score.”
        </blockquote>
      </div>

      <p>
        As if memorizing and tracking 16 cards weren’t enough to make you marvel
        at the memory skills of a KG, consider all of the other information one
        must memorize and relay to the team. I did my own tracking and was able
        to find out from another successful ace tracking team leader, what
        skills he requires of a KG on his team. They must:
      </p>
      <ul>
        <li>Remember and indicate what sequences are being picked up.</li>
        <li>
          At the end of the shoe, they have 1 minute to recite the sequences
          picked up.
        </li>
        <li>
          Signal the number of hands to be played and the corresponding bet
          size.
        </li>
        <li>
          Remember what happened for each ace bet: to self, to the dealer, no
          appearance. (Determine accuracy of their own skills)
        </li>
      </ul>

      <p>KG’s surely earn their keep, don’t they?!</p>

      <p>
        I believe the first time I saw Shuffle Tracking explained in-depth was
        in 1995 in a 3-part series in a periodical called Blackjack Forum (cover
        of one of the issues below). These magazines are still a great reference
        for anyone wanting to learn about blackjack and advantage plays and was
        also published by Arnold Snyder. The pull quote describes Sequential/Ace
        Tracking.
      </p>

      <div className={imageContainer}>
        <img src={bjForum} alt="Magazine Cover" />
      </div>

      <h2>Cheating, Memory, and Sleight of Hand: A Devastating Combination</h2>

      <aside>
        *Note: When working with my casino clients, I’ll often ask trainees,
        “What is the best game to cheat at a casino?” Answers vary, but the
        correct answer, in my opinion, is baccarat. This is not just because
        this game generally has the highest stakes in the casino. In baccarat,
        once the cards are shuffled or replaced with pre-shuffled cards and a
        new shoe begins, the cards will have only one possible outcome, as the
        game is dealt with one specific set of rules that do not change. This is
        unlike blackjack, where players have the option of hitting or standing,
        splitting, doubling down, playing extra hands, sitting out a hand,
        leaving the table, etc., all of which would affect the hands to follow.
        Although baccarat players also share the options of sitting out a hand
        or leaving the table, these actions do not affect the outcome of the
        cards in baccarat at all.{" "}
      </aside>

      <h5>SLEIGHT OF HAND IS KEY IN SOME OF THE BIGGEST CASINO SCAMS</h5>

      <p>
        Baccarat scams involving false shuffles (sleight of hand trickery that
        appears to mix the cards, yet in reality keeps all or just a packet/slug
        of cards in a particular order) are probably as old as the game itself.
        There are several documented incidents of crooked dealers, who were
        skilled at sleight of hand and employed false shuffles after their
        accomplices wrote down the order of the cards from the previous shoe.
        The accomplice would then leave the table to calculate all possible
        outcomes of the slug recorded. After identifying key cards during play,
        the scribe and confederates would know exactly when the slug would
        appear and whether to bet player, banker, or tie (or even the many side
        bets now available) for multiple hands to follow because they knew the
        exact order of the cards that were about to be dealt.
      </p>

      <p>
        When they were busted in 2006, the{" "}
        <a href="https://www.justice.gov/opa/pr/co-founder-casino-cheating-criminal-enterprise-sentenced-36-months-prison-targeting-casinos">
          Tran Organization
        </a>{" "}
        had already pulled off one of the biggest baccarat scams ever (up until
        that time), swindling casinos across the US and Canada out of an
        estimated $7,000,000. They used a network of players and dealers, whom
        they recruited and trained to do false shuffles (that pesky sleight of
        hand skill). A team member would record a slug during one shoe by
        writing it on a baccarat score card. Then, the dealer would employ some
        false shuffles, but not for the entire shuffle procedure, to retain the
        order of the slug. Knowing the exact order of the cards, and thus the
        outcome of the hands involving those cards, the team would capitalize on
        the information.{" "}
      </p>

      <aside>
        Note: A crooked dealer might not do ALL false shuffles throughout the
        shuffle procedures, but only a few to keep a targeted segment of cards
        in order. This can make it more difficult to spot, as the cheating
        dealers who have highly developed sleight of hand skills can execute
        legitimate shuffles and false shuffles that are identical.{" "}
      </aside>

      <p>
        Some casinos have eliminated baccarat score cards and have installed
        electronic scoreboards instead (see picture below), which display the
        results of the hands of the current shoe. Many legitimate baccarat
        players are superstitious, so they sometimes like to bet with a trend
        when they see it. Electronic scoreboards are a great tool for them to
        quickly walk by a table and spot a trend they’d like to bet on or
        against (like banker shown to win many times throughout this shoe), and
        subsequently, an ingenious marketing tool for the casino.
      </p>

      <div className={imageContainer}>
        <img src={baccaratCard} alt="Electronic Scorecard" />
      </div>

      <aside>
        The electronic baccarat scoreboard to the left displays the results of
        the hands. The dealer just finished dealing the 59th hand of the shoe
        (“GAME NUMBER 59”). The outcomes of the last five hands, including the
        59th, in order, were two ties (green circles with a “T”) followed by
        three bankers (red circles with a “B”), which are all in the 10th
        column.
      </aside>

      <p>
        As table games evolve, so do the methods that cheaters use to defraud
        them. With paper scorecards and pencils removed from the game, so too
        were the tools that the slug scam scribe used for deception. What can a
        slug team use to ply their trade now? MEMORY! Of course. Which also
        eliminates the evidence used to prove the scam, save for a confession.{" "}
      </p>

      <div className={imageContainer}>
        <img src={dailyTimes} alt="News article" />
      </div>

      <p>
        The news article above (referenced earlier) is from the Macau Daily
        Times. This is not the only article I could find on this type of
        baccarat cheating, but it is the first article on the subject that I saw
        after I presented this very concept at a conference two years prior to
        it.
      </p>

      <p>
        Even before electronic scoreboards became popular, I was thinking about
        the classic baccarat slug scam and thought, “What if the cards were
        memorized instead of written? Then that physical evidence would be
        eliminated.” So I started to look up different memorization techniques
        and fell in love with mnemonics. I read everything I could get my hands
        on, talked to anyone who would share their knowledge of mnemonics, and
        practiced day and night until I became proficient at memorizing a slug
        of cards. (Not much has changed for me since then. I still do memory
        drills daily and read anything I can get my hands on about memory
        improvement.) Originally, I practiced to see if my suspicions were
        realistic and they are, which was proven by this incident in Macau.{" "}
      </p>

      <aside>
        ***Note: There is no hubris here. I don’t have delusions of being a
        cheat mastermind, though I can think the way they do. I am not claiming
        to have been the first to have combined mnemonics and casino cheating,
        it was merely a thought I had that was worth exploring. Furthermore, one
        does not have to be as deft at memorization skills as I am to use it
        against a casino.
      </aside>

      <p>
        The article describes how a dealer was taken to a unit and was shown how
        to memorize cards while he shuffled them. The end of the pull quote
        shows the dealer’s earnings from this scam. When the dealer was caught,
        he had accumulated HK $600,000, (or what he believed to be) a 20% share
        of the total illegal earnings, which would make that a HK $3,000,000
        scam. The most technical parts of this scam were the dealer peeking and
        memorizing the cards and the false shuffles, and the most ingenious part
        of the plot was that the dealer could not identify his accomplices
        because he had no idea who they were- to whom he sent the texts with the
        sequences of cards that he memorized!
      </p>

      <h2>Shuffle Machines</h2>

      <p>
        A couple of the reasons to use shuffle machines are to improve
        productivity* and to protect the games from crooked dealers who would
        scam the casino using false shuffles. So, in a casino using shuffle
        machines, could a dealer still use false (manual) shuffles to swindle a
        game? Of course they can!{" "}
      </p>

      <p>
        Picture this scenario. A dealer removes the cards from the shuffle
        machine, gives a player the cut card, then presents the cards for the
        player to cut. Somewhere between offering the cards for the cut and
        placing the cards into the shoe to deal, the dealer “accidentally”
        spills the cards onto the table with many of the cards landing face up.
        Since the other set of cards are unavailable because they are in the
        shuffle machine, which can take several minutes to process, the
        supervisor would tell the dealer to do a manual shuffle to keep the game
        moving. For a crooked dealer, it’s like giving him/her a license to
        steal. The dealer could then do the same as the dealer in the Macau scam
        previously noted.
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <iframe
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube.com/embed/Cjc9DlHRbS0"
          title="Sal Piacente Demonstrates a Memory Scam"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <aside>
        *If you want to learn more about shuffling cards, productivity, shuffle
        scams, and so much more, I highly recommend Casino Game Protection: A
        Comprehensive Guide by my good friend and mentor Steve Forte. I have
        been in gaming most of my life (35 years) and have collected a huge
        amount of books relating to Game Protection. Steve’s book has replaced
        all of them for me. This book is out of print and hard to find, but I
        believe it is worth every penny and the effort to get it. Whether you
        are an executive interested in maximizing profitability, game
        efficiency, and protecting games, a Table Games manager or supervisor or
        Surveillance personnel looking to make yourself more valuable and
        knowledgeable, or just someone interested in the subject, this book is
        an indispensable.
      </aside>

      <p>
        The picture on the previous page illustrates how a spilled shoe might
        look. As the dealer gathers the cards, he/she would quickly memorize a
        slug. In this case, the dealer is peaking at cards at the top section of
        cards in his left hand by fanning them. After the cards are gathered,
        the dealer would employ false shuffles, (only with the memorized slug,
        then legitimate shuffles with the rest of the shoe) retaining the order
        of the slug. When the dealer begins to deal the new shoe, his/her
        confederates are looking for a specific card sequence. In a multi-deck
        shoe, there are multiples of each card value, so to determine whether or
        not they found their slug, they will seek at least three key cards in a
        row. The odds of a multi-deck shoe having three exact cards (suit &amp;
        value) in the same order together is very minute. Once the cheats spot
        the key cards, they know that the rest of the slug will follow and can
        easily calculate the outcome of the following hand(s). Though the video
        (follow the link under the picture) demonstrates an example of this in
        blackjack, this same scam is also applicable to baccarat.
      </p>

      <div className={preShuffle}>
        <h2>
          Pre-Shuffled Cards: A Simple Solution and the Perils That Go with It
        </h2>
      </div>

      <p>
        Many casinos around the world, with the biggest baccarat play, have been
        using pre-shuffled cards (cards that are shuffled before they are used
        on the table, whether by the card manufacturer or in a room designated
        for that purpose at the casino) to take game productivity one step
        further than the shuffle
      </p>

      <p>
        machines. This is also great for Game Protection, when used correctly,
        but does not fully eliminate the vulnerabilities of the game. While
        pre-shuffled cards can eliminate false shuffle scams (where there are no
        further manual shuffles required of the dealers- a big mistake some
        casinos are making that creates more vulnerabilities, in my opinion, and
        defeats the purpose of pre-shuffled cards), crooked dealers can still
        access a sequence of cards during the cut.
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <iframe
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube.com/embed/Hrgz4b7P65U"
          title="Sal Piacente Demonstrates a Dilution Shuffle Slug Scam"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        The picture on the previous page was taken during a training sessions
        where I demonstrated this baccarat scam. When the dealer takes the cards
        out of the pre-shuffle box for the cut procedure, he/she can manipulate
        some of the cards so that they see and quickly memorize them (the cards
        at the rear, in this case, which are hidden from overhead cameras). For
        the following card sequence, 0 is the value of jacks, queens, or kings
        in baccarat, A = ace, and R is red. In the video, the dealer sees: 4, 0,
        A, 2, 0, 8, 6, 0, JR, QR, and 5R.{" "}
      </p>

      <aside>
        Note: Ideally, key cards should be identified by both suit and value, to
        leave the least room for error. One only needs to see a tiny portion to
        identify the number or letter of the index and could miss the symbol for
        the suit, which happened in this case. The dealer was trying to keep his
        movements tight to help conceal what he is doing and to avoid being
        caught. 🗝 For this sequence, the key cards are a red 5, a red queen, and
        a red jack. Because the dealer is peeking and memorizing cards from the
        back moving forward, the memorized sequence needs to be reversed to
        exploit that information.{" "}
      </aside>

      <p>
        To memorize 10 cards takes less than 2 seconds, which is more than
        enough for one coup. With baccarat having the biggest limits on the
        casino floor and multiple players being able to bet on the same side, a
        coup like this can significantly threaten the game’s hold percentage. On
        the video, the key cards come out on the same hand, which results in a
        tie: player 2 and bank 2. This means that they have found the slug and
        the next hand dealt will come out in this order: 0, 6, 8, and 0,
        resulting in a natural win for the player: player 8 and bank 6.
      </p>

      <p>
        These are just a few examples of how a dealer can obtain a slug of cards
        and memorize them to scam a casino. This is by no means an exhaustive
        list. There are many opportunities for a dealer to obtain a slug, yet
        they must know how to record or memorize the cards to exploit the
        information. There are also scams and advantage plays, using memory with
        the One2Six continuous shuffling machine, which are relatively easily
        accomplished. However, there are tells for many of these scams that
        seasoned and properly trained Table Games and Surveillance staff can
        detect.{" "}
      </p>

      <h2>
        <span className={bonus}>*Bonus Section</span> <br /> Hand Shuffle Shoe
        Scam - Sleight of Hand and Memory Nearly Undetectable
      </h2>

      <aside>
        NOTE: As far as I know, I am the only Game Protection Expert who
        demonstrates this scam in training. This is difficult to detect.
      </aside>

      <p>
        There are still casinos today who have their dealers do hand shuffles on
        the game, which could be for many reasons. Maybe their budgets do not
        allow for the expense of shuffle machines or pre-shuffled cards, etc.
        For whatever reason, if your casino still uses hand shuffles on the shoe
        games, this section is especially for you, as well as anyone interested
        in how crooked dealers can collude with players on this type of game,
        using memory and sleight of hand.{" "}
      </p>

      <p>
        In this case, we examine a two-part dilution shuffle, which is a staple
        in the industry. During the first half of the shuffle procedure, the
        dealer places all of the cards in a single pile at the center of the
        table, then splits that in half. Next, the dealer takes about a
        half-deck from the top of each pile and riffles them together, placing
        the combined cards into a new pile in the center. For the remainder of
        this first part of the process, the dealer will take a half-deck from
        the center (cards already shuffled together) and a half-deck from either
        the left or right pile, riffle them together, then place them on top of
        the cards in the center. (See picture below) Then, he/she will alternate
        each side: center-right, center-left, center right, etc.; until all of
        the cards are shuffled and placed into one pile at the center.{" "}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <iframe
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube.com/embed/QNM6XPb1VnA"
          title="Sal Piacente Demonstrates a Dilution Shuffle Slug Scam"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        Most of the scam takes place during this first half of the procedure.
        During the shuffle, the dealer would riffle the cards too high. This
        enables him/her to see the index, and thus the value, of the card at the
        top of the segment in the left hand. The dealer memorizes the card and
        continues the shuffle procedure. On the next riffle, the dealer will
        again riffle high to see that top card in his/her left hand, and using
        sleight of hand, deliberately place the peeked card directly on top of
        the card on top of the pile in the right hand, which is the first card
        peeked and memorized. Through this first part of the shuffle procedure,
        the dealer will continue to build this slug, one card/riffle at a time,
        carefully ensuring that the top few memorized cards in the right hand
        land directly under the top card in the right hand.{" "}
      </p>

      <aside>
        NOTE: The dealer builds the slug from the bottom up and the slug will be
        dealt from the top down- reverse order of what dealer sees while
        shuffling.
      </aside>

      <p>
        For the second half of the procedure, the dealer will split the cards in
        half again, but this time, he/she will only take about a half-deck from
        the left and a half-deck from the right and riffle them together, then
        place them into one pile in the center, repeating this until the cards
        are again all in one pile (not alternating sides with the center pile as
        in the first half of the procedure). The high riffle is only done during
        the first riffle of this second half of the procedure. The dealer can
        either riffle high to peek one or multiple cards to place on top of the
        slug that he/she is building.
      </p>

      <p>
        After the shuffle, the dealer presents a confederate the cards for the
        cut and this player cuts the cards where the slug will wind up near the
        top of the shoe. Then, the cheats just wait to see their key cards and
        get their “Game On.¹”{" "}
      </p>

      <aside>
        IMPORTANT: Generally, in today’s casino environment, most dealers lack
        good hand shuffle skills. I see many clump shuffles and high riffles,
        which go uncorrected by the supervisors. This is a mistake! When you
        poor procedures to continue, it becomes much more difficult to spot the
        cheats among them.
      </aside>

      <h2>Memory - a Tool for All</h2>

      <p>
        A trained memory is not just a device for those looking to take
        advantage of or swindle a casino game. Memory can also aid casino staff
        in observing a play, whether it’s during a review or while watching it
        live, and to increase productivity. Those who can efficiently recall
        players’ names, account numbers, table and pit numbers, camera and zone
        numbers, buy-ins, wagers, payouts, win/loss, etc., can get the job done
        more efficiently and accurately, and provide better customer service to
        patrons than someone who must constantly refer to something else, like a
        touch screen or piece of paper for information, at which time they may
        also miss something important that is happening on the game.{" "}
      </p>

      <p>
        Now that you know what memory has to do with Game Protection, I hope you
        can appreciate its potential power as much as I do. As the casino
        industry continues to evolve, so do methods cheaters use to bilk casinos
        and AP’s use to get an edge over the house, and memory can play a part
        in both legitimate and illegitimate ways to win. As we try to keep up in
        this cat and mouse game, I think it’s crucial that we equip the people
        tasked with protecting casino assets with as much knowledge as possible
        to do the best job they can. I find that most of my trainees are
        grateful for the education, especially those who take pride in their
        work, and morale is improved because they know their bosses care enough
        to invest in them.{" "}
      </p>
      <p>
        On a final note, if you think you have a “bad memory” and you can’t
        learn to improve it, I think you are not giving yourself enough credit.
        Provided you don’t have a medical condition that would hinder your
        progress, you can greatly improve your ability to recall facts, numbers,
        procedures, etc. I was able to do it for myself and help many others do
        the same. The mnemonics we use are based on things you already know.
        Reach out if I can help.{" "}
      </p>

      <aside>
        <em>
          *Special thanks to Dee Jabier Piacente, my wife and partner in
          everything, who was instrumental in this article.
        </em>
      </aside>

      <aside>
        Sal Piacente is a 35-year casino veteran, the president of UniverSal
        Game Protection Development, Inc., an instructor for UNR’s Gaming
        Management Program, and a frequent speaker at casino industry
        conferences, trade shows, and conventions worldwide.
      </aside>
    </article>
  )
}

export default WhatYouShouldKnowArticle

export const Head = () => <Seo title="What You Should Know About Memory" />
