import React from "react"
import Layout from "../components/layout"
import WhatYouShouldKnowArticle from "../components/whatyoushouldknowarticle/whatyoushouldknowarticle"

const WhatYouShouldKnowPage = () => {
  return (
    <Layout>
      <WhatYouShouldKnowArticle />
    </Layout>
  )
}

export default WhatYouShouldKnowPage
